import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useNuxtApp } from "#imports";
export function createAdapter(vuetifyOptions) {
  vuetifyOptions.locale = {};
  const nuxtApp = useNuxtApp();
  const i18n = nuxtApp.$i18n;
  const current = i18n.locale;
  const fallback = i18n.fallbackLocale;
  const messages = i18n.messages;
  const currentLocale = ref(current.value);
  vuetifyOptions.locale.rtl = i18n.locales.value.reduce((acc, locale) => {
    acc[locale.code] = locale.dir === "rtl";
    return acc;
  }, {});
  watch(currentLocale, (val, oldVal) => {
    if (oldVal)
      i18n.setLocale(val);
  }, { immediate: true, flush: "post" });
  nuxtApp.hook("i18n:localeSwitched", ({ newLocale }) => {
    currentLocale.value = newLocale;
  });
  vuetifyOptions.locale.adapter = {
    name: "nuxt-vue-i18n",
    current: currentLocale,
    fallback,
    messages,
    t: (key, ...params) => i18n.t(key, params),
    n: i18n.n,
    provide: createProvideFunction({ current: currentLocale, fallback, messages })
  };
}
function createProvideFunction(data) {
  return (props) => {
    const currentLocale = ref(props.locale ?? data.current.value);
    const i18n = useI18n({
      locale: currentLocale.value,
      fallbackLocale: data.fallback.value,
      messages: data.messages.value,
      useScope: "local",
      legacy: false,
      inheritLocale: false
    });
    watch(currentLocale, (val, oldVal) => {
      if (oldVal)
        i18n.setLocale(val);
    }, { immediate: true, flush: "post" });
    const t = wrapI18n(i18n.t);
    const n = wrapI18n(i18n.n);
    return {
      name: "nuxt-vue-i18n",
      current: currentLocale,
      fallback: data.fallback,
      messages: data.messages,
      t,
      n,
      provide: createProvideFunction({ current: currentLocale, fallback: data.fallback, messages: data.messages })
    };
  };
}
function wrapI18n(t) {
  return (...args) => {
    return t(...args);
  };
}
